<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '新建'}喷涂工单`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item label="车牌">
        <select-maintain
          v-model="form.data.plateNo"
          :ajax="{
            action: 'GET /enocloud/common/vehicle',
            params: (value) => ({ plateNo: value, rsexp: 'id,plateNo,vin,vehicleSpec,owner[id,name,cellphone]' })
          }"
          :props="{ label: 'plateNo', value: '' }"
          value-key="id"
          remote
          allow-create
          :disabled="form.data.status?.code === 'S' || form.data.status?.code === 'D'"
          @change="form.plateNo.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="收费类型">
        <select-maintain
          v-model="form.data.paintType"
          :options="store.user.sprayTenant?.paintTypes.map((item) => item.paintType)"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="收费标准" prop="'chargingStandard.code'">
        <select-maintain
          v-model="form.data.chargingStandard"
          :options="form.chargingStandard.data"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="车品牌" prop="vehicleSpec">
        <cascader-maintain
          v-model="form.data.vehicleSpec"
          :ajax="{ action: 'GET /enocloud/common/vehicle/brand', params: (params) => (params.payload = { depth: 2 }) }"
          :props="{ label: 'name', value: 'name', children: 'models' }"
          allow-create
          class="w-full"
        ></cascader-maintain>
      </en-form-item>
      <en-form-item label="车架号" prop="vin">
        <en-input v-model="form.data.vin" :disabled="form.data.status?.code === 'S' || form.data.status?.code === 'D'"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnospraySprayDefinitions['WorkOrderDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    success: (id: number | undefined) => ['number', 'undefinded'].includes(typeof id)
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          this.form.init()
          if (this.data?.id) {
            this.form.data.id = this.data?.id
            await this.form.get()
            if (this.store.user.tenant?.id === 'ENOCH' || this.form.data.status?.code === 'C' || this.form.data.status?.code === 'S') {
              this.form.chargingStandard.data = this.form.data.chargingStandard ? [this.form.data.chargingStandard] : []
            } else {
              this.form.chargingStandard.data =
                this.store.user.sprayTenant?.chargingStandard?.code === 'B'
                  ? [
                      { code: 'A', message: 'A表单', description: '', type: '' },
                      { code: 'B', message: 'B表单', description: '', type: '' }
                    ]
                  : [this.store.user.sprayTenant!.chargingStandard!]
            }
          } else {
            this.form.chargingStandard.data =
              this.store.user.sprayTenant?.chargingStandard?.code === 'B'
                ? [
                    { code: 'A', message: 'A表单', description: '', type: '' },
                    { code: 'B', message: 'B表单', description: '', type: '' }
                  ]
                : [this.store.user.sprayTenant!.chargingStandard!]

            this.form.data.chargingStandard = this.store.user.sprayTenant!.chargingStandard!
            this.form.data.paintType = this.store.user.sprayTenant!.paintTypes?.[0] ? this.store.user.sprayTenant!.paintTypes[0]! : {}
          }
        }
      }
    }
  },

  config: {
    children: {
      form: {
        rules: {
          plateNo: [{ required: true, message: '请填写车牌' }],
          'chargingStandard.code': [{ required: true, message: '请选择收费标准' }],
          vehicleSpec: [{ required: true, type: 'array', message: '请选择车品牌' }],
          vin: [{ required: true, message: '请填写vin码' }]
        },
        ajax: {
          get: {
            action: 'GET /enospray/workorder/:workOrderId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enospray/workorder',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enospray/workorder',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        children: {
          plateNo: {
            async change(value: EnocloudCommonDefinitions['VehicleDto'] | string) {
              if (typeof value === 'object') {
                this.form.data.plateNo = value.plateNo
                this.form.data.vin = value.vin
                this.form.data.cellphone = value.owner ? value.owner.cellphone : ''
                this.form.data.vehicleSpec = value.vehicleSpec.length ? value.vehicleSpec : []
              } else {
                this.form.loading = true
                try {
                  const res = await this.ajax('GET /enospray/workorder', { payload: { plateNo: value, active: true } })
                  if (res.data[0]) {
                    this.form.data.vehicleSpec = res.data[0].vehicleSpec
                    this.form.data.vin = res.data[0].vin
                    this.form.data.cellphone = res.data[0].cellphone
                    this.form.data.colorCode = res.data[0].colorCode
                    this.form.data.colorName = res.data[0].colorName
                    this.form.data.year = res.data[0].year
                  }
                } finally {
                  this.form.loading = false
                }
              }
            }
          },
          chargingStandard: {
            data: [] as EnospraySprayDefinitions['LookupDto'][]
          },
          paintType: {}
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.footer.cancel.click()
            this.emit('success', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  }
})
</script>
